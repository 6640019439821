import React from 'react';
import './services.css';
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ServiceDescription = (props) => {
    const {icon, title, type = 'fas'} = props;

    return (
        <Grid
            container
            item
            xs={12}
            sm={6}
            md={3}
            direction={'column'}
            justify={'center'}
            alignContent={'center'}
            alignItems={'center'}
        >
            <FontAwesomeIcon icon={[type, icon]} size="7x" style={{color: 'gray'}} />
            <Typography className="titleServiceDescription">{title}</Typography>
        </Grid>
    );

};
    
export default ServiceDescription;