import './App.css';
import { Container, Grid, Typography } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import 'fontsource-roboto';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Services from './pages/services/Services';

import logo from './imgs/Contein.png';

library.add(fab, fas);

function App() {
  return (
    <Router>
      <div>
        <Container>
            <Grid
                container
                item
                direction="row"
                justify="flex-start"
                alignItems="center"
                xs={6}
                sm={3}
            >
                <Link to="/"><img src={logo} alt="" /></Link>
            </Grid>
            <Grid
                container
                direction="row"
                // justify="space-around"
                justify="space-evenly"
                alignItems="center"
                className="links"
            >
              <Typography variant="body1" component="span">
                <Link to="/servicios">Servicios</Link>
              </Typography>
              <Typography variant="body1" component="span">
                <Link to="/contacto">Contactanos</Link>
              </Typography>
            </Grid>
        </Container>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/contacto">
            <Contact />
          </Route>
          <Route path="/servicios">
            <Services />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="beforeFooter"
      ></Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className="footer"
      >
        <Container className="footerContainer">
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid xs={12} sm={4} item><img src={logo} alt="" /></Grid>
            {/* <Grid xs={12} sm={4} item direction="column" alignItems="stretch" className="footerText">
              <Typography component="span">Parque Ejecutivo</Typography>
              <Typography component="span">Calle 1234</Typography>
              <Typography component="span">Colonia 12345</Typography>
              <Typography component="span">Monterrey</Typography>
              <Typography component="span">Oficina 00</Typography>
            </Grid> */}
            <Grid xs={12} sm={4} item className="footerText">
              <Typography component="span">Contáctenos</Typography>
              <Typography component="span">81 1969 5684</Typography>
              <a href="mailto:Info@contein.com.mx?subject=Contacto"><Typography component="span">Info@contein.com.mx</Typography></a>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Router>
  );
}

export default App;
