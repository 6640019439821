import React from 'react';
import './services.css';
import { Container, Grid, Typography, Slide } from '@material-ui/core';
import ServiceDescription from './ServiceDescription'

import serviceImg from '../../imgs/Services.jpg'

const Services = () => {

    const mapServices = [
        {indx: 1, icon: 'server', title: 'Venta de equipo y Licenciamiento'},
        {indx: 2, icon: 'tools', title: 'Servicio de Mantenimiento y Reparación de equipo de cómputo e impresión'},
        {indx: 3, icon: 'truck-loading', title: 'Servicio de Arrendamiento para proyectos'},
        {indx: 4, icon: 'network-wired', title: 'Cableado Estructurado y Fibra Óptica'},
        {indx: 5, icon: 'eye', title: 'Control de Acceso y CCTV'},
        {indx: 6, icon: 'uncharted', title: 'Servicios de instalación, Configuración y Mantenimiento', type: 'fab'},
        {indx: 7, icon: 'cloud', title: 'Virtualización'},
        {indx: 8, icon: 'save', title: 'Respaldos'},
        {indx: 9, icon: 'wifi', title: 'Redes y Seguridad'},
        {indx: 10, icon: 'award', title: 'Póliza Contein Gold De Garantía Extendida'},
    ]

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className="imgGrid"
        >
            <div className="contactImg" style={{'backgroundImage': `url(${serviceImg})`}}>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography component="span">Servicios</Typography>
                </Slide>
            </div>

            <Container>
                <Grid className="serviceContainer" spacing={10} alignItems={'flex-start'} justify="space-evenly" container>
                    {mapServices.map(ms =>
                        <ServiceDescription
                            icon={ms.icon}
                            title={ms.title}
                            type={ms.type}
                            key={ms.indx}
                        />
                    )}
                </Grid>
            </Container>
        </Grid>
    );

};
    
export default Services;