import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './home.css';
import { Container, Typography, Slide, Grid } from '@material-ui/core';

import img1 from '../../imgs/Backup.jpg';
import img2 from '../../imgs/Conf.jpg';
import img3 from '../../imgs/Network.jpg';
import img4 from '../../imgs/Virtual.jpg';

const slideImages = [
    {indx: 1, imgUrl: img1, text: 'Respaldos'},
    {indx: 2, imgUrl: img2, text: 'Configuración y Mantenimiento'},
    {indx: 3, imgUrl: img3, text: 'Redes'},
    {indx: 4, imgUrl: img4, text: 'Virtualización'}
];

const Home = () => {
    const [nextIndex, setNextIndex] = React.useState(0);

    const properties = {
        autoplay: true,
        onChange: (previous, next) => {
            setNextIndex(next);
        }
    };

    return (
        <Container className="root">
            <div className="slide-container">
                <Fade {...properties}>
                    {slideImages.map((si, index) => 
                        <div key={si.indx} className="each-fade">
                            <div style={{'backgroundImage': `url(${si.imgUrl})`}}>
                                <Slide direction="up" in={nextIndex === index} mountOnEnter unmountOnExit>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        className="slideText"
                                    >
                                        {si.text}
                                    </Typography>
                                </Slide>
                            </div>
                        </div>
                    )}
                </Fade>
            </div>

            <Grid container style={{marginTop: 50}} spacing={5}>
                <Grid container item justify={'flex-start'} sm={12} md={6} direction="column" alignItems="center">
                    <Typography variant="h2" component="h2">Misión</Typography>
                    <Typography variant="body1" style={{textAlign: 'justify'}}>Convertirnos en parte de su departamento de IT, buscando brindar asesoría profesional en proyectos IT y de reducción de costos. Siendo integrador, desarrollador e implementador de soluciones de tecnología de información, otorgando al cliente final una solución completa para su negocio.</Typography>
                </Grid>
                <Grid container item justify={'flex-start'} sm={12} md={6} direction="column" alignItems="center">
                    <Typography variant="h2" component="h2">Visión</Typography>
                    <Typography variant="body1" style={{textAlign: 'justify'}}>Ser una empresa reconocida a nivel nacional como empresa seria, profesional, y responsable, que brinda soluciones IT y de negocio de manera  honesta, completa y de calidad. Siempre teniendo un serio respeto por su tiempo.</Typography>
                </Grid>
                <Grid container item justify={'flex-start'} xs={12} md={8} direction="column" alignItems="center" style={{margin: '150px auto 0 auto'}}>
                    <Typography variant="h2" component="h2">Valores</Typography>
                    <ol>
                        <li>
                            <Typography variant="body1" style={{textAlign: 'justify'}}><b>ACTITUD DE SERVICIO</b>: en nuestra empresa procuramos servir con amabilidad, proactivos e interesados en la prestación eficiente de nuestros servicios.</Typography>
                        </li>
                        <li>
                            <Typography variant="body1" style={{textAlign: 'justify'}}><b>INNOVACION</b>: siempre estar al día con  los productos y tecnologías más actuales que existan en el mercado, con una capacitación continua de nuestro personal.</Typography>
                        </li>
                        <li>
                            <Typography variant="body1" style={{textAlign: 'justify'}}><b>HONESTIDAD</b>: actuar de manera transparente con nuestros clientes para así forjar una relación de confianza.</Typography>
                        </li>
                        <li>
                            <Typography variant="body1" style={{textAlign: 'justify'}}><b>VALOR AGREGADO</b>: Siempre dar un extra en nuestros servicios sobrepasando las expectativas creadas por nuestros clientes buscando integrarse como parte de su equipo de trabajo, apoyando en proyectos, planeación y diseño de soluciones de TI.</Typography>
                        </li>
                    </ol>
                </Grid>
            </Grid>
        </Container>
    )
};
    
export default Home;