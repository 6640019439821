import React from 'react';
import './contact.css';
import { Container, Grid, Typography, Slide, Paper } from '@material-ui/core';

import contactImg from '../../imgs/Support.jpg'

const Contact = () => {

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className="imgGrid"
        >
            <div className="contactImg" style={{'backgroundImage': `url(${contactImg})`}}>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Typography component="span">Contáctenos</Typography>
                </Slide>
            </div>

            <Container>
                <Grid container>
                    <Paper elevation={3} className="tarjetaContacto">
                        <Typography component="span" className="cardTitle">PÓNGASE EN CONTACTO</Typography>
                        <div className="newParagraph">
                            <Typography component="span" className="number">81 1969 5684</Typography>
                            <a href="mailto:Info@contein.com.mx?subject=Contacto">
                                <Typography component="span" className="mail">Info@contein.com.mx</Typography>
                            </a>
                        </div>
                        {/* <div className="newParagraph">
                            <Typography component="span" className="direction">Parque Ejecutivo</Typography>
                            <Typography component="span" className="direction">Calle 1234</Typography>
                            <Typography component="span" className="direction">Colonia 12345</Typography>
                            <Typography component="span" className="directionSmall">Monterrey</Typography>
                            <Typography component="span" className="directionSmall">Oficina 00</Typography>
                        </div> */}
                        <div className="newParagraphGrid">
                            <Typography component="span" className="direction">Lun - Vie</Typography>
                            <Typography component="span" className="direction">9:00 - 17:00</Typography>
                            <Typography component="span" className="direction">Sábado - Dom</Typography>
                            <Typography component="span" className="direction">Cerrado</Typography>
                        </div>
                    </Paper>
                </Grid>
            </Container>
        </Grid>
    );

};
    
export default Contact;